<template>
  <div>
    <div class="bg">
      <div style="width: 1400px; height: 526px; margin: 0 auto">
        <div style="position: relative; text-align: center">
          <img src="@/assets/image/icon41.png" alt="" class="bg_img" />
          <div
            style="
              position: absolute;
              font-size: 52px;
              font-family: zihun143-zhengkuchaojihei,
                zihun143-zhengkuchaojihei-Regular;
              font-weight: 400;
              text-align: left;
              color: #2c53fa;
              line-height: 79px;
              letter-spacing: 2.34px;
              text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
              top: 60px;
              left: 200px;
              display: flex;
              flex-direction: column;
            "
          >
            <span>郁金香文库</span>
            <span>知识共享平台</span>
          </div>
        </div>
      </div>
      <div class="contain1">
        <div class="contain1_title">关于我们</div>
        <div class="contain1_line"></div>
        <div v-html="processedRichTextContent" class="text"></div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner_contain">
        <div class="partner_contain_tip">
          <div>
            合作伙伴
            <p></p>
          </div>
          <!-- <div>查看全部<img src="@/assets/image/icon21.png" alt="" /></div> -->
        </div>
        <div class="partner_list">
          <div
            class="partner_list_item"
            v-for="(item, index) in partner"
            :key="index"
            @click="goPartner(item.url)"
          >
            <img :src="$imgURL + item.logo" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { get } from "/utils/request";
export default {
  data() {
    return {
      token: "",
      keyword: "",
      current: "推荐文章",
      screen: [
        { id: 1, name: "推荐文章", key: "recommend_at desc" },
        { id: 2, name: "最新上传", key: "id desc" },
      ],
      page: 1,
      size: 10,
      list: [1, 1, 1, 1, 1, 1], //文档列表
      content: "",
      partner: [1, 1, 1, 1, 1, 1], //合作伙伴
    };
  },
  created() {},
  watch: {},
  mounted() {
    console.log("-----this.$route.query", this.$route.query);
    this.getText(this.$route.query.text);
  },
  computed: {
    processedRichTextContent() {
      return this.processImages(this.content);
    },
  },
  methods: {
    search() {},
    changeScreen(item) {
      this.current = item.name;
    },
    getText(identifier) {
      get("/article", { identifier: identifier }).then((res) => {
        console.log("返回的富文本---", res);
        this.content = res.content;
      });
      // 合作伙伴
      get("/config/friend", {}).then((res) => {
        console.log(res);
        this.partner = res.data ?? [];
      });
    },
    goPartner(url) {
      window.open(url);
    },
    processImages(content) {
      // 正则匹配图片路径
      const regex = /<img.*?src=["'](.*?)["']/g;
      let result;
      let newContent = content;
      // 使用正则表达式找到所有图片路径
      while ((result = regex.exec(content)) !== null) {
        const imagePath = result[1];
        // 拼接完整图片路径
        const fullImagePath = this.$imgURL + imagePath;
        // 替换原始图片路径并添加样式
        newContent = newContent.replace(
          `<img src="${imagePath}"`,
          `<img src="${fullImagePath}" style="max-width:100%;height:auto;"`
        );
      }
      return newContent;
    },
  },
};
</script>
    
  <style lang="scss" scoped>
.bg {
  width: 100%;
  background: url("../../assets/image/bg.png");
  background-size: 100% 622px;
  background-repeat: no-repeat;
  padding: 50px 0 0;
  box-sizing: border-box;
  .bg_img {
    width: 1400px;
    height: 526px;
    // margin: 0 auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  .contain1 {
    width: 1400px;
    background: #ffffff;
    border-radius: 6px;
    margin: 20px auto;
    padding: 20px;
    box-sizing: border-box;
    .contain1_title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
    }
    .contain1_line {
      height: 1px;
      background: #e0e0e0;
      margin: 20px 0;
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      letter-spacing: 0.7px;
    }
  }
  .partner_contain {
    width: 1400px;
    background: #ffffff;
    border-radius: 6px;
    margin: 0 auto 50px;
    padding: 20px 20px 0;
    box-sizing: border-box;
    .partner_contain_tip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
        letter-spacing: 0.9px;
        position: relative;
        p {
          width: 75px;
          height: 7px;
          background: linear-gradient(180deg, #23c48c, #33da61);
          position: absolute;
          bottom: -1px;
          opacity: 0.8;
        }
      }
      div:nth-child(2) {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        letter-spacing: 0.8px;
        img {
          width: 22px;
          height: 22px;
          margin-left: 6px;
        }
      }
    }
    .partner_list {
      display: flex;
      flex-wrap: wrap;
      .partner_list_item {
        width: 322px;
        height: 136px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        margin-right: 20px;
        margin-bottom: 20px;
        img {
          width: 325px;
          height: 136px;
          border-radius: 6px;
        }
      }
      .partner_list_item:nth-child(4n) {
        margin-right: 0px;
      }
    }
  }
}
</style>