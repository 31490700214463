import { render, staticRenderFns } from "./aboutMe.vue?vue&type=template&id=534deae3&scoped=true&"
import script from "./aboutMe.vue?vue&type=script&lang=js&"
export * from "./aboutMe.vue?vue&type=script&lang=js&"
import style0 from "./aboutMe.vue?vue&type=style&index=0&id=534deae3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534deae3",
  null
  
)

export default component.exports